import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useQuery } from 'react-query';

import folderIcon from '../../assets/images/my-library/folder.svg';

import MeetingService from '../../services/MeetingService';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import { MEETING_TABS } from '../../constants/main';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import Breadcrumbs from '../../components/Breadcrumbs';
import MeetingsList from '../../components/MeetingsList';
import MeetingHistoryList from '../../components/MeetingHistoryList';
import Subfolder from '../../components/Library/Subfolder';
import classes from './styles.module.scss';

const MEETING_TABS_LABELS = {
  [MEETING_TABS.MY_VIDEOS]: 'My SOP Videos',
  [MEETING_TABS.MOBILE_UPLOADS]: 'Mobile Uploads',
  [MEETING_TABS.SAVED]: 'My Saved Sections',
};

const subfolders = [
  { tab: MEETING_TABS.MY_VIDEOS, title: 'My SOP Videos' },
  { tab: MEETING_TABS.MOBILE_UPLOADS, title: 'My Mobile Uploads' },
  { tab: MEETING_TABS.SAVED, title: 'My Saved Sections' },
];

export default function MeetingsPage() {
  const [searchInput, setSearchInput] = useState('');
  const [selectedTab, setSelectedTab] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [breadcrumbsOptions, setBreadcrumbsOptions] = useState([
    { label: 'My Videos', to: '/my-videos' },
  ]);
  const [title, setTitle] = useState('');

  const { id: meetingId } = useParams();
  const isMobile = useCheckMobileScreen();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const destinationMeetingId = queryParams.get('meetingId');

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

  const { data: meeting, isLoading } = useQuery(
    ['meeting', meetingId],
    () => MeetingService.getMeeting(meetingId),
    {
      enabled: !!meetingId,
    }
  );

  const handleChangeTab = useCallback(
    (tab) => {
      setSearchParams({ tab });
    },
    [navigate]
  );

  useEffect(() => {
    if (isLoading) return;
    const tab = searchParams.get('tab');
    const isHistoryPage = location.pathname.endsWith('/history');

    const tempOptions = [{ label: 'My Videos', to: '/my-videos' }];

    if (tab) {
      setSelectedTab(tab);
      setTitle(MEETING_TABS_LABELS[tab]);
      tempOptions.push({
        label: MEETING_TABS_LABELS[tab],
        to: `/my-videos?tab=${MEETING_TABS.MY_VIDEOS}&p=${
          searchParams.get('p') || 1
        }`,
      });
    } else if (isHistoryPage && meeting) {
      setSelectedTab(MEETING_TABS.MY_VIDEOS);
      tempOptions.push({
        label: MEETING_TABS_LABELS[MEETING_TABS.MY_VIDEOS],
        to:
          breadcrumbsOptions[1]?.to ||
          `/my-videos?tab=${MEETING_TABS.MY_VIDEOS}&p=1`,
      });
      setTitle(meeting.title);
    } else {
      setSelectedTab(null);
      setTitle('');
    }

    setBreadcrumbsOptions(tempOptions);
  }, [location, meeting, isLoading]);

  let content = (
    <div className={classes.foldersContainer}>
      {subfolders.map((subfolder) => (
        <Subfolder
          key={subfolder.tab}
          onClick={() => handleChangeTab(subfolder.tab)}
          title={subfolder.title}
          icon={folderIcon}
        />
      ))}
    </div>
  );

  if (selectedTab) {
    content = (
      <MeetingsList
        classes={classes}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        tab={selectedTab}
        destinationMeetingId={destinationMeetingId}
        setBreadcrumbsOptions={setBreadcrumbsOptions}
        meetingTabsLabels={MEETING_TABS_LABELS}
      />
    );
  }

  if (meetingId) {
    content = <MeetingHistoryList classes={classes} meetingId={meetingId} />;
  }

  return (
    <div className={classes.MeetingsPage}>
      <Header
        hasUserBlock
        hasSearch={isMobile}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
      />
      <Navigation />
      <div className={classes.container}>
        <div className={classes.breadcrumbsContainer}>
          <Breadcrumbs
            breadcrumbsOptions={breadcrumbsOptions}
            showBackButton={!!destinationMeetingId}
          />
          <h1 className={classes.title}>{title}</h1>
        </div>
        {content}
      </div>
    </div>
  );
}
