/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext, useRef, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient, useMutation } from 'react-query';

import * as yup from 'yup';
import axios from 'axios';
import { useFormik } from 'formik';
import classNames from 'classnames';

import userIcon from '../../assets/images/usericon.webp';
import accountIcon from '../../assets/images/profile/account.svg';
import backIcon from '../../assets/images/profile/back.svg';
import deleteAccountIcon from '../../assets/images/profile/deleteAccount.svg';
// import notificationsIcon from '../../assets/images/profile/notifications.svg';
import { UiContext } from '../../context/UiContext';
import { logout } from '../../helpers/globalAuth';
import UserService from '../../services/UserService';
import { STATIC_URL } from '../../constants/main';
import RoundedInput from '../../components/Inputs/RoundedInput';
import ConfirmButton from '../../components/Buttons/ConfirmButton';
import FilledButton from '../../components/Buttons/FilledButton';
import Switch from '../../components/Switch';
import Header from '../../components/Header';
import ConfirmActionModal from '../../modals/ConfirmActionModal';
import classes from './styles.module.scss';

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .required('This field is required')
    .matches(/^\S+$/, 'This field cannot contain white space'),
  lastName: yup
    .string()
    .trim()
    .required('This field is required')
    .matches(/^\S+$/, 'This field cannot contain white space'),
  title: yup.string().trim().required('This field is required'),
  companyName: yup.string().trim(),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email')
    .required('This field is required'),
  password: yup
    .string()
    .min(8, 'The password should have at minimum length of 8'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .when('password', {
      is: (password) => password?.length > 0,
      then: yup.string().required('Passwords must match'),
    }),
});

const VIEW = {
  ACCOUNT: 'account',
  NOTIFICATIONS: 'notifications',
};

export default function ProfilePage() {
  const [currentView, setCurrentView] = useState(VIEW.ACCOUNT);
  const [image, setImage] = useState(null);
  const [
    isConfirmAccountRemovalModalVisible,
    setIsConfirmAccountRemovalModalVisible,
  ] = useState(false);

  const navigate = useNavigate();
  const { data } = useQuery('me', UserService.getMe);
  const queryClient = useQueryClient();

  const { notifyError, notifySuccess } = useContext(UiContext);

  const fileInputRef = useRef();

  const userInfoMutation = useMutation(UserService.updateMe, {
    onSuccess: (user) => {
      queryClient.setQueryData('me', user);
      notifySuccess('Saved successfully');
    },
    onError: (error) => {
      notifyError(error?.response?.data?.message);
    },
  });

  const { mutate: userPhotoUploadMutate } = useMutation(
    UserService.uploadPhoto,
    {
      onSuccess: (user) => {
        queryClient.setQueryData('me', user);
      },
      onError: (error) => {
        notifyError(error?.response?.data?.message);
      },
    }
  );

  const { mutate: userPhotoDeleteMutate } = useMutation(
    UserService.deletePhoto,
    {
      onSuccess: (user) => {
        queryClient.setQueryData('me', user);
      },
      onError: (error) => {
        notifyError(error?.response?.data?.message);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      firstName: data?.name?.split(' ')[0],
      lastName: data?.name?.split(' ')[1],
      title: data?.title || '',
      email: data?.email || '',
      companyName: data?.companyName || '',
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: ({
      firstName,
      lastName,
      title,
      email,
      companyName,
      password,
      confirmPassword,
    }) => {
      userInfoMutation.mutate({
        name: `${firstName} ${lastName}`,
        title,
        email,
        companyName,
        password,
        confirmPassword,
      });
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (image) {
      userPhotoUploadMutate(image);
    }
  }, [image, userPhotoUploadMutate]);

  const deleteUser = async () => {
    try {
      await axios.delete('/users/me');
      logout(() => {
        queryClient.removeQueries();
        navigate('/sign-in');
      });
    } catch (error) {
      console.log(error);
    }
  };

  const hasError = useMemo(
    () => Object.values(formik.errors).some((error) => error),
    [formik.errors]
  );

  return (
    <div className={classes.ProfilePage}>
      <Header />
      <div className={classes.container}>
        <div className={classes.sideBar}>
          <div className={classes.back} onClick={() => navigate(-1)}>
            <img src={backIcon} alt="Back" />
            <span>Back</span>
          </div>
          <div className={classes.listsContainer}>
            <ul>
              <li
                className={classNames(
                  currentView === VIEW.ACCOUNT &&
                    !isConfirmAccountRemovalModalVisible &&
                    classes.active
                )}
                onClick={() => setCurrentView(VIEW.ACCOUNT)}
              >
                <img src={accountIcon} alt="account" />
                <span>My account</span>
              </li>

              {/* <li
                className={classNames(
                  currentView === VIEW.NOTIFICATIONS &&
                    !isConfirmAccountRemovalModalVisible &&
                    classes.active
                )}
                onClick={() => setCurrentView(VIEW.NOTIFICATIONS)}
              >
                <img src={notificationsIcon} alt="notifications" />
                <span>Notifications</span>
              </li> */}
            </ul>

            <ul>
              <li
                className={classNames(
                  isConfirmAccountRemovalModalVisible && classes.active
                )}
                onClick={() => setIsConfirmAccountRemovalModalVisible(true)}
              >
                <img src={deleteAccountIcon} alt="delete" />
                <span>Delete account</span>
              </li>
            </ul>
          </div>
        </div>

        <div className={classes.main}>
          {currentView === VIEW.ACCOUNT && (
            <div className={classes.account}>
              <div className={classes.userPhoto}>
                <input
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(event) => setImage(event.target.files[0])}
                  ref={fileInputRef}
                  accept="image/*"
                />
                <img
                  src={data?.photoPath ? STATIC_URL + data.photoPath : userIcon}
                  alt="User"
                />
                <ul>
                  <li>
                    <FilledButton onClick={() => fileInputRef.current.click()}>
                      Upload Image
                    </FilledButton>
                  </li>

                  <li>
                    <button
                      type="button"
                      disabled={!data?.photoPath}
                      className={classes.removeButton}
                      onClick={userPhotoDeleteMutate}
                    >
                      Remove Image
                    </button>
                  </li>
                </ul>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <h1>Public information</h1>
                <div className={classes.row}>
                  <RoundedInput
                    label={
                      formik.errors.firstName && formik.touched.firstName
                        ? formik.errors.firstName
                        : 'First name'
                    }
                    value={formik.values.firstName}
                    errors={formik.errors.firstName}
                    isTouched={formik.touched.firstName}
                    type="text"
                    name="firstName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  <RoundedInput
                    label={
                      formik.errors.lastName && formik.touched.lastName
                        ? formik.errors.lastName
                        : 'Last name'
                    }
                    value={formik.values.lastName}
                    errors={formik.errors.lastName}
                    isTouched={formik.touched.lastName}
                    type="text"
                    name="lastName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>

                <RoundedInput
                  label={
                    formik.errors.title && formik.touched.title
                      ? formik.errors.title
                      : 'Your title'
                  }
                  value={formik.values.title}
                  errors={formik.errors.title}
                  isTouched={formik.touched.title}
                  type="text"
                  name="title"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                <RoundedInput
                  label={
                    formik.errors.companyName && formik.touched.companyName
                      ? formik.errors.companyName
                      : 'Company name'
                  }
                  value={formik.values.companyName}
                  type="text"
                  name="companyName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                <RoundedInput
                  label={
                    formik.errors.email && formik.touched.email
                      ? formik.errors.email
                      : 'Your email'
                  }
                  value={formik.values.email}
                  errors={formik.errors.email}
                  isTouched={formik.touched.email}
                  type="email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                <RoundedInput
                  label={
                    formik.errors.password && formik.touched.password
                      ? formik.errors.password
                      : 'Your password'
                  }
                  value={formik.values.password}
                  errors={formik.errors.password}
                  isTouched={formik.touched.password}
                  autoComplete="new-password"
                  type="password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                <RoundedInput
                  label={
                    formik.errors.confirmPassword &&
                    formik.touched.confirmPassword
                      ? formik.errors.confirmPassword
                      : 'Confirm password'
                  }
                  value={formik.values.confirmPassword}
                  errors={formik.errors.confirmPassword}
                  isTouched={formik.touched.confirmPassword}
                  type="password"
                  name="confirmPassword"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <ConfirmButton isDisabled={hasError} />
              </form>
            </div>
          )}

          {currentView === VIEW.NOTIFICATIONS && (
            <div className={classes.notificationPreferences}>
              <h1>Notification Type</h1>
              <div className={classes.list}>
                <span className={classes.hint}>Enable Email Notification</span>
                {[
                  'When a registered user views your meeting',
                  'When your meeting has been successfully shared',
                  'When you receive an invitation to  collaborate for a meeting',
                  'Once your meeting is done processing and is ready to share',
                ].map((preference) => (
                  <div key={preference} className={classes.item}>
                    <p>{preference}</p>
                    <Switch />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <ConfirmActionModal
        show={isConfirmAccountRemovalModalVisible}
        handleClose={() => setIsConfirmAccountRemovalModalVisible(false)}
        onConfirm={deleteUser}
        title="Are you sure you want to delete your account?"
        message="You will not be able to recover any created or assigned meetings once deleted."
      />
    </div>
  );
}
