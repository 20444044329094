/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo } from 'react';

import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import moment from 'moment';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import MeetingService from '../../services/MeetingService';
import FilledButton from '../../components/Buttons/FilledButton';
import classes from './styles.module.scss';

function transformMeetingUserData(meetingUser) {
  const { User } = meetingUser;

  const firstName = User.name.split(' ')[0];
  const lastName = User.name.split(' ')[1];
  const jobTitle = User.title;
  let status = meetingUser.status === 'viewed' ? 'Viewed' : 'Not yet viewed';
  const completionDate = meetingUser.completionDate
    ? moment(meetingUser.completionDate).format('MM/DD/YYYY')
    : '-';

  if (meetingUser.userRole === 'creator') {
    status = 'Owner';
  }

  return { firstName, lastName, jobTitle, status, completionDate };
}

const exportAsPdf = (
  data,
  viewedUsersCount,
  totalAssignedUsers,
  completionRate,
  meetingTitle
) => {
  /* eslint-disable new-cap */
  const doc = new jsPDF();
  const head = [
    ['First Name', 'Last Name', 'Job Title', 'Status', 'Completion Date'],
  ];

  doc.setFontSize(12);

  doc.text(
    `Completion status: ${viewedUsersCount} out of ${totalAssignedUsers} assigned users viewed video`,
    16,
    12
  );

  doc.text(`Completion rate: ${completionRate}% viewer completion`, 16, 18);

  doc.setFontSize(24);

  autoTable(doc, {
    startY: 30,
    headStyles: { fillColor: '#F5658C' },
    head,
    body: data,
    theme: 'grid',
  });

  doc.save(`viewer-report-${meetingTitle}.pdf`);
};

export default function ViewerReportPage() {
  const { id } = useParams();

  const { data: meeting } = useQuery(['meetings'], () =>
    MeetingService.getMeeting(id)
  );

  const { data: meetingUsers } = useQuery(['folderUsers'], () =>
    MeetingService.getMeetingUsers(id)
  );

  const viewedMeetingUsers = useMemo(
    () =>
      meetingUsers?.filter((meetingUser) => meetingUser.status === 'viewed'),
    [meetingUsers]
  );

  const viewedUsersCount = viewedMeetingUsers?.length || 0;
  const totalAssignedUsers = meetingUsers?.length || 0;
  const completionRate =
    Math.round((viewedUsersCount / totalAssignedUsers) * 100) || 0;

  const handleExport = () => {
    const formattedMeetingUsers = meetingUsers.map((meetingUser) => {
      const { firstName, lastName, jobTitle, status, completionDate } =
        transformMeetingUserData(meetingUser);

      return [firstName, lastName, jobTitle, status, completionDate];
    });

    exportAsPdf(
      formattedMeetingUsers,
      viewedUsersCount,
      totalAssignedUsers,
      completionRate,
      meeting.title
    );
  };

  return (
    <div className={classes.ViewerReportPage}>
      <div className={classes.container}>
        <header className={classes.header}>
          <h2>Viewer Report</h2>
          <h1>SnapSOP</h1>
        </header>

        <div className={classes.reportInfo}>
          <div>
            <h3 className={classes.meetingTitle}>
              SOP Video Title: {meeting?.title}
            </h3>
            <p>
              <b>Completion Status:</b> {viewedUsersCount} out of{' '}
              {totalAssignedUsers} assigned users viewed video
            </p>
            <p className={classes.completionRate}>
              <b>Completion Rate:</b> {completionRate}% viewer completion
            </p>
          </div>
          <div className={classes.buttonContainer}>
            <FilledButton onClick={handleExport}>Export</FilledButton>
          </div>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Job Title</th>
            <th>Status</th>
            <th>Completion Date</th>
          </tr>
        </thead>
        <tbody>
          {meetingUsers?.map((meetingUser) => {
            const { firstName, lastName, jobTitle, status, completionDate } =
              transformMeetingUserData(meetingUser);

            return (
              <tr key={meetingUser.id}>
                <td>{firstName}</td>
                <td>{lastName}</td>
                <td>{jobTitle}</td>
                <td>{status}</td>
                <td>{completionDate}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
