/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import moment from 'moment/moment';

import { UiContext } from '../../context/UiContext';
import { hasAuthData } from '../../helpers/authStorage';
import { timezones } from '../../modals/SelectTimeZoneModal/timezones';
import UserService from '../../services/UserService';
import AlertModal from '../../modals/AlertModal';
import FilledButton from '../../components/Buttons/FilledButton';
import RoundedInput from '../../components/Inputs/RoundedInput';
import DotsLoader from '../../components/DotsLoader';
import MeetingPage from '../MeetingPage';
import classes from './styles.module.scss';

const normalizeTimezoneLabel = (label) =>
  label?.split(' - ').slice(0, -1).join('');

const digitsOnly = (value) => /^\d+$/.test(value);

const emailAndPasswordValidationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email')
    .required('Email is required'),
  password: yup
    .string()
    .trim()
    .test('Digits only', 'The field should have digits only', digitsOnly)
    .test('len', 'Must be exactly 9 digits', (val) => val.length === 9)
    .required('Passcode is required'),
});

const emailValidationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email')
    .required('Email is required'),
});

const passwordValidationSchema = yup.object({
  password: yup
    .string()
    .trim()
    .test('Digits only', 'The field should have digits only', digitsOnly)
    .test('len', 'Must be exactly 9 digits', (val) => val.length === 9)
    .required('Password is required'),
});

export default function SharedMeetingPage() {
  const [isMeetingExpiredModalVisible, setIsMeetingExpiredModalVisible] =
    useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isPasswordVerified, setIsPasswordVerified] = useState(false);
  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [meeting, setMeeting] = useState({});
  const [accessPassword, setAccessPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const { notifyError, setRedirectAfterLogin } = useContext(UiContext);

  const security = search.get('security');
  const expiresAt = search.get('expiration');

  const { data: user } = useQuery('me', UserService.getMe, {
    enabled: hasAuthData(),
    retry: false,
  });

  useEffect(() => {
    const isMeetingExpired = new Date(+expiresAt) < new Date();

    if (expiresAt && isMeetingExpired) {
      setIsMeetingExpiredModalVisible(true);
    }
  }, []);

  const checkEmail = async ({ email }) => {
    try {
      const { data } = await axios.post(`/meetings/private/${id}/check-email`, {
        email,
      });

      setIsLoading(true);

      setTimeout(() => {
        setIsEmailChecked(true);
        setIsLoading(false);

        if (data.skip) {
          return;
        }

        if (data?.meetingUser.userId === user?.id) {
          navigate(`/my-videos/${id}`);
        } else {
          setRedirectAfterLogin(`/my-videos/${id}`);
          navigate('/sign-in');
        }
      }, 1500);
    } catch (error) {
      notifyError(error?.response?.data?.message);
    }
  };

  const verifyEmail = async ({ email }) => {
    try {
      await axios.post(`/meetings/private/${id}/verify-email`, {
        email,
      });

      setIsEmailVerified(true);
    } catch (error) {
      notifyError(error?.response?.data?.message);
    }
  };

  const getSharedMeeting = async ({ password, email }) => {
    try {
      const params = {
        password,
        security,
      };

      if (expiresAt) {
        params.expiresAt = new Date(+expiresAt);
      }

      if (email) {
        params.email = email;
      }

      if (user) {
        params.userId = user.id;
      }

      const response = await axios.get(`/meetings/private/${id}`, {
        params,
      });

      setMeeting(response.data);
      setAccessPassword(password);
      setIsPasswordVerified(true);
    } catch (error) {
      notifyError(error?.response?.data?.message);
    }
  };

  const emailSubmit = ({ email, password }) => {
    if (security === 'private') {
      if (isEmailChecked) {
        verifyEmail({ email });
      } else {
        checkEmail({ email });
      }
    } else {
      getSharedMeeting({ password });
    }
  };

  const emailAndPasswordFormik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: emailAndPasswordValidationSchema,
    onSubmit: getSharedMeeting,
  });

  const emailFormik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: emailValidationSchema,
    onSubmit: emailSubmit,
  });

  const passwordFormik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: passwordValidationSchema,
    onSubmit: getSharedMeeting,
  });

  let emailError;
  let passwordError;

  switch (security) {
    case 'private':
      emailError = emailFormik.errors.email && emailFormik.touched.email && (
        <div className={classes.error}>{emailFormik.errors.email}</div>
      );

      passwordError = passwordFormik.errors.password &&
        passwordFormik.touched.password && (
          <div className={classes.error}>{passwordFormik.errors.password}</div>
        );
      break;

    case 'restricted':
      emailError = emailAndPasswordFormik.errors.email &&
        emailAndPasswordFormik.touched.email && (
          <div className={classes.error}>
            {emailAndPasswordFormik.errors.email}
          </div>
        );

      passwordError = emailAndPasswordFormik.errors.password &&
        emailAndPasswordFormik.touched.password && (
          <div className={classes.error}>
            {emailAndPasswordFormik.errors.password}
          </div>
        );
      break;

    case 'public':
      emailError = emailFormik.errors.email && emailFormik.touched.email && (
        <div className={classes.error}>{emailFormik.errors.email}</div>
      );

      passwordError = null;
      break;

    default:
      break;
  }

  const emailInput = (
    <label>
      <RoundedInput
        type="text"
        value={
          security === 'restricted'
            ? emailAndPasswordFormik.values.email
            : emailFormik.values.email
        }
        name="email"
        onChange={
          security === 'restricted'
            ? emailAndPasswordFormik.handleChange
            : emailFormik.handleChange
        }
        placeholder="Email"
      />
      {emailError}
    </label>
  );

  const passwordInput = (
    <label>
      <RoundedInput
        type="text"
        value={
          security === 'restricted'
            ? emailAndPasswordFormik.values.password
            : passwordFormik.values.password
        }
        name="password"
        onChange={
          security === 'restricted'
            ? emailAndPasswordFormik.handleChange
            : passwordFormik.handleChange
        }
        placeholder={
          security === 'private' ? 'enter verification code here' : 'Passcode'
        }
      />
      {passwordError}
    </label>
  );

  let hint = 'To view this secured video, please enter your information:';

  if (isEmailVerified) {
    hint =
      "Verification code sent to your email, don't forget to check your spam";
  } else if (isEmailChecked && !isEmailVerified) {
    hint =
      "Looks like you're not a registered user, we'll send a code to your email to access your meeting";
  }

  let form;

  switch (security) {
    case 'private':
      form = (
        <form
          onSubmit={
            isEmailVerified
              ? passwordFormik.handleSubmit
              : emailFormik.handleSubmit
          }
        >
          {!isEmailChecked || !isEmailVerified ? emailInput : passwordInput}
          <FilledButton type="submit">
            {!isEmailChecked && 'Submit'}

            {isEmailChecked &&
              (isEmailVerified ? 'View meeting' : 'Send verification code')}
          </FilledButton>
        </form>
      );
      break;

    case 'restricted':
      form = (
        <form onSubmit={emailAndPasswordFormik.handleSubmit}>
          {emailInput}
          {passwordInput}
          <FilledButton type="submit">View video</FilledButton>
        </form>
      );
      break;

    case 'public':
      form = (
        <form onSubmit={emailFormik.handleSubmit}>
          {emailInput}
          <FilledButton type="submit">View video</FilledButton>
        </form>
      );
      break;

    default:
      break;
  }

  const timezoneOffset = moment(+expiresAt).format('Z');
  const normalizedTimezoneLabel =
    normalizeTimezoneLabel(timezones[timezoneOffset]?.label) || '';

  return (
    <>
      {isPasswordVerified ? (
        <MeetingPage
          meeting={meeting}
          accessPassword={accessPassword}
          getSharedMeeting={getSharedMeeting}
          email={
            emailFormik.values.email.trim() ||
            emailAndPasswordFormik.values.email.trim()
          }
        />
      ) : (
        <div className={classes.SharedMeetingPage}>
          <div className={classes.main}>
            <h1>
              <span>{search.get('title')}</span>
              <br />
              <span>created by: {search.get('invitedBy')}</span>
            </h1>
            <p className={classes.hint}>{hint}</p>

            {form}

            <div className={classes.processingContainer}>
              {isLoading && (
                <>
                  <DotsLoader />
                  <span>checking if user is registered on SnapSOP</span>
                </>
              )}
            </div>

            <p className={classes.expirationInfo}>
              {expiresAt && (
                <>
                  <span>This video can no longer be viewed after:</span>{' '}
                  <span>
                    {`${moment(+expiresAt).format('MMMM Do YYYY, h:mm A')}`}
                    <br />
                    {`${normalizedTimezoneLabel}`}
                  </span>
                </>
              )}
            </p>
          </div>

          <AlertModal
            show={isMeetingExpiredModalVisible}
            title="Oops!"
            message="Your video has expired. Please contact the video creator to request access."
            label="Okay"
            hasConfirmButton
            onConfirm={() => navigate('/my-videos')}
          />
        </div>
      )}
    </>
  );
}
