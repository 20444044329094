import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import Pagination from 'react-js-pagination';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';

import infoIcon from '../../assets/images/my-meetings/info.svg';
import playIcon from '../../assets/images/my-meetings/play.svg';
import copyIcon from '../../assets/images/copyIcon.svg';

import UserService from '../../services/UserService';
import MeetingService from '../../services/MeetingService';

export default function MeetingHistoryList({ classes, meetingId }) {
  const [page, setPage] = useState(1);

  const navigate = useNavigate();

  const { data: meetingVersions } = useQuery(
    ['meetings', page],
    () => MeetingService.getMeetingVersions(meetingId, page),
    {
      keepPreviousData: true,
    }
  );

  const { data: user } = useQuery('me', UserService.getMe);

  const switchPage = (pageNumber) => {
    setPage(pageNumber);
  };

  useEffect(() => {
    if (meetingVersions?.rows) {
      if (meetingVersions.rows.length === 0 && page !== 1) {
        setPage((prevPage) => prevPage - 1);
      }
    }
  }, [meetingVersions?.rows, page]);

  const handleRestoreMeeting = async (meetingVersionId) => {
    try {
      const meeting = await MeetingService.restoreMeetingByVersion(
        meetingVersionId
      );

      navigate(`/my-videos/${meeting.id}/edit`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead>
            <tr>
              <th>Title</th>
              <th>Version</th>
              <th>Published at</th>
              <th>Created by</th>
              <th>Comments</th>
            </tr>
          </thead>
          {meetingVersions?.rows && (
            <tbody>
              {meetingVersions?.rows &&
                meetingVersions?.rows.map((meetingVersion) => {
                  const duration =
                    meetingVersion?.duration < 60
                      ? `${parseInt(meetingVersion?.duration, 10)} sec`
                      : `${Math.round(
                          parseInt(meetingVersion?.duration, 10) / 60
                        )} min`;

                  return (
                    <tr key={meetingVersion.id}>
                      <td className={classes.title}>{meetingVersion.title}</td>
                      <td className={classes.version}>
                        {meetingVersion.version}
                      </td>
                      <td>
                        {moment(meetingVersion.updatedAt).format('MM/DD/YYYY')}
                      </td>
                      <td>
                        {meetingVersion.Meeting?.userId === user?.id ? (
                          'Me'
                        ) : (
                          <>
                            {meetingVersion?.User.name}
                            <br />
                            {meetingVersion?.User.title}
                          </>
                        )}
                      </td>
                      <td className={classes.comment}>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>{meetingVersion.comment}</Tooltip>}
                        >
                          <span>{meetingVersion.comment}</span>
                        </OverlayTrigger>
                      </td>
                      <td className={classes.buttons}>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>
                              <div className={classes.tooltipContent}>
                                <p>Duration: {duration}</p>

                                {!!meetingVersion.Meeting.MeetingReviews
                                  .length && (
                                  <>
                                    Approved by:
                                    <br />
                                    {meetingVersion.Meeting.MeetingReviews.map(
                                      (review) => (
                                        <>
                                          {review.User
                                            ? `${review.User.name}, ${review.User.title}`
                                            : review.email}
                                          <br />
                                        </>
                                      )
                                    )}
                                  </>
                                )}
                              </div>
                            </Tooltip>
                          }
                        >
                          <button type="button">
                            <img src={infoIcon} alt="Info" />
                          </button>
                        </OverlayTrigger>

                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Play</Tooltip>}
                        >
                          <button
                            type="button"
                            onClick={() =>
                              navigate(
                                `/my-videos/${meetingVersion.meetingId}?version=${meetingVersion.version}`
                              )
                            }
                          >
                            <img src={playIcon} alt="Play" />
                          </button>
                        </OverlayTrigger>

                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Make a copy</Tooltip>}
                        >
                          <button
                            type="button"
                            onClick={() =>
                              handleRestoreMeeting(meetingVersion.id)
                            }
                          >
                            <img src={copyIcon} alt="Copy" />
                          </button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          )}
        </table>
      </div>

      {+meetingVersions?.count > 5 && (
        <div className={classes.paginationContainer}>
          <Pagination
            itemClass={classes.button}
            innerClass={classes.buttonsList}
            activeClass={classes.active}
            disabledClass={classes.disabled}
            activePage={page}
            itemsCountPerPage={5}
            totalItemsCount={+meetingVersions?.count || 0}
            pageRangeDisplayed={5}
            prevPageText="Prev"
            nextPageText="Next"
            onChange={switchPage}
          />
        </div>
      )}
    </>
  );
}
