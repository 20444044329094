/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

const EditableTitle = ({ children: title, handleUpdateTitle, isEditable }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newTitle, setNewTitle] = useState(title);
  const [isEditButtonVisible, setIsEditButtonVisible] = useState(false);
  const [showFullTitle, setShowFullTitle] = useState(false);

  const inputRef = useRef(null);
  const titleRef = useRef(null);

  const handleSubmit = async () => {
    try {
      await handleUpdateTitle(newTitle.trim());

      setIsEditing(false);
    } catch (error) {
      console.log(error);
    }
  };

  useOnClickOutside(inputRef, () => handleSubmit());

  const handleClickTitle = (e) => {
    if (!isEditable) {
      return;
    }

    e.stopPropagation();

    if (!newTitle.trim().length) {
      setNewTitle(title);
    }

    setIsEditing(true);
    setTimeout(() => {
      inputRef.current.focus();
      inputRef.current.select();
    }, 0);
  };

  const handleChangeTitle = (event) => {
    setNewTitle(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (!titleRef) return;

    if (titleRef.current.offsetWidth < titleRef.current.scrollWidth) {
      setShowFullTitle(true);
    }
  }, [titleRef]);

  return (
    <div
      onMouseOver={() => setIsEditButtonVisible(true)}
      onMouseLeave={() => setIsEditButtonVisible(false)}
      className={classes.EditableTitle}
    >
      {isEditing ? (
        <input
          ref={inputRef}
          value={newTitle}
          onChange={handleChangeTitle}
          onClick={(e) => e.stopPropagation()}
          onKeyDown={handleKeyPress}
          type="text"
        />
      ) : (
        <>
          <span className={classes.tooltip} ref={titleRef}>
            {title}
          </span>
          {showFullTitle && (
            <span className={classes.tooltiptext}>{title}</span>
          )}
          {isEditable && isEditButtonVisible && (
            <button
              onClick={handleClickTitle}
              type="button"
              className={classes.editButton}
            />
          )}
        </>
      )}
    </div>
  );
};

export default EditableTitle;
